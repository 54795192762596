<template>
  <div class="main inner clearFix">
    <div class="mainL fl menu">
      <p>{{ commonList.dictName }}</p>
      <template>
        <ul>
          <li
            v-for="(item, index) in commonList.children"
            :key="item.id"
            :class="{ cur: index == selectIndex }"
            @click="footSelect(index)"
          >
            <span class="num">{{
              setItem(index, commonList.children, "dictName")
            }}</span>
          </li>
        </ul>
      </template>
    </div>
    <div class="mainR fr">
      <div class="contents">
        <h2>{{ setItem(selectIndex, commonList.children, "dictName") }}</h2>
        <div class="contentsdiv" v-html="content"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    commonList: {
      type: Object,
      default: () => {
        return [];
      },
    },
    selectFootIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectIndex: this.$store.state.footTabsSelect,
      cosntent: "",
      contentList: this.$store.state.contentList,
      content:''
    };
  },
  methods: {
    // 赋值
    setItem(index, arr, key) {
      let e = Object.assign({}, arr[index]);
      return e[key];
    },
    footSelect(index) {
      this.selectIndex = index;
      this.$store.dispatch("SetFootTabsSelect", index);
      this.$emit("footSelectChange", index);
    },
  },
  mounted() {
      let newcontent = this.setItem(this.$store.state.footTabsSelect, this.commonList.children, "dictName");
      var arr = JSON.parse(sessionStorage.getItem("ContentList"));
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].title == newcontent) {
          this.content = arr[i].content;
          break;
        }
      }
  },
  watch: {
    selectFootIndex: function(newVal) {
      this.selectIndex = newVal;
      let newcontent = this.setItem(newVal, this.commonList.children, "dictName");
      var arr = JSON.parse(sessionStorage.getItem("ContentList"));
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].title == newcontent) {
          this.content = arr[i].content;
          break;
        }
      }
    },
  },
};
</script>
<style scoped>
.main {
  width: 1220px;
  padding: 20px 0 30px;
}
.linner {
  width: 1220px;
  margin: 0 auto;
}
.mainL {
  width: 285px;
}
.fl {
  float: left;
}
.mainL p {
  height: 60px;
  line-height: 60px;
  padding-left: 20px;
  font-size: 20px;
  color: #fff;
  background-color: #007bc7;
  margin: 0;
}
ul {
  display: flex;
  flex-direction: column;
}
.menu > ul > li {
  width: 100%;
  display: block;
  list-style: none;
  overflow: visible;
  display: flex;
  align-items: center;
}
.menu > ul > li > .num {
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding-bottom: 0 !important;
  height: 50px;
  color: #333333;
  padding-left: 20px;
  line-height: 50px;
  background: #fafafa url(../../assets/img/commonparts/licur.jpg) no-repeat
    245px center;
  text-decoration: none !important;
  outline: none;
  font-weight: normal;
}
.cur .num {
  color: #007bc7 !important;
  background: #fafafa url(../../assets/img/commonparts/li.jpg) no-repeat 245px
    center !important;
}
.cur {
  border-left: 2px solid #fafafa;
}
.mainR {
  width: 920px;
}
.fr {
  float: right;
}
.contents h2 {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 20px;
  color: #007bc7;
  text-align: center;
}
.contentsdiv {
  line-height: 200%;
}
</style>
