<template>
  <div class="page">
    <!-- 轮播 -->
    <div class="swiperBox">
      <Swiper :SWiperList="SWiperList"></Swiper>
      <div class="tabsbox">
        <div class="tabs-card">
          <div
            v-for="(item, index) in tabsList"
            :key="item.id"
            class="tabs-item"
            :class="{
              clickBg: index == clickIndex,
              hoverBg: index == hoverIndex,
            }"
            @click="clickIndex = index"
            @mouseover="hoverIndex = index"
            @mouseout="hoverIndex = -1"
          >
            {{ item.dictName }}
          </div>
        </div>
      </div>
    </div>
    <!-- tabs切换内容 -->
    <div class="TabsBox">
      <Tabs :tabsIndex="tabsIndex"></Tabs>
    </div>
    <!-- 服务i流程 -->
    <div class="service">
      <img src="../assets/img/service_02.jpg" alt="" />
    </div>
    <!-- 客户眼中得我们 -->
    <Customer :ItemList="ItemList"></Customer>
    <!-- 我们服务过的客户 -->
    <WeService></WeService>
  </div>
</template>
<script>
import Swiper from "@/components/index/Swiper";
import Tabs from "@/components/index/Tabs";
import Customer from "@/components/index/Customer";
import WeService from "@/components/index/WeService";
export default {
  components: {
    Swiper,
    Tabs,
    Customer,
    WeService,
  },
  data() {
    return {
      tabsList: [],
      hoverIndex: -1, //hover值
      clickIndex: 0, //选中值
      tabsIndex: 0, //传给组件 的值
      ItemList: [], // 客户眼中得我们
      SWiperList:[]
    };
  },
  created() {
    this.getTabsList();
    this.tabsIndex = this.clickIndex;
    this.getWeServer();
    this.getSwiper()
  },
  mounted() {},
  methods: {
    getWeServer() {
      this.$http
        .post("/stock_admin/api/article/queryArticleListByPage", {
          type: "product",
        })
        .then((res) => {
          this.ItemList = res.result.list;
        });
    },
    getSwiper() {
      this.$http
        .get("/stock_admin/api/banner/selectBanner", {
          type: "product",
        })
        .then((res) => {
          this.SWiperList = res.result;
        });
    },
    getTabsList() {
      this.$http.get("/stock_admin/api/dict/queryAll").then((res) => {
        let arr = res.result;
        let err = [
          { name: "工商注册", id: "3" },
          { name: "会计服务", id: "4" },
          { name: "商标代理", id: "5" },
          { name: "专项审批", id: "6" },
          { name: "法律咨询", id: "7" },
          { name: "劳务派遣", id: "8" },
          { name: "创业孵化", id: "9" },
        ];

        for (let i = 0; i < arr.length; i++) {
          for (let q = 0; q < err.length; q++) {
            if (err[q].name == arr[i].dictName) {
              err[q] = arr[i];
            }
          }
        }
        this.tabsList = err;
      });
    },
   
  },

  watch: {
    // 监听下标变化
    clickIndex(newVal) {
      this.tabsIndex = newVal;
    },
  },
};
</script>
<style scoped>
.swiperBox {
  margin: auto;
  position: relative;
}
/* tabs */
.tabsbox {
  width: 1220px;
  height: 70px !important;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 998;
}
.tabs-card {
  width: 1220px;
  height: 70px !important;
  display: flex;
}
.tabs-item {
  height: 70px !important;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #52a1ff;
  color: #fff;
  background-color: #1e6bad;
  color: #fff;
  font-size: 20px;
}
.hoverBg {
  background-color: #fff !important;
  color: #1e6bad !important;
  border: none !important;
}
.clickBg {
  background-color: #fff !important;
  color: #1e6bad !important;
  border: none !important;
}
.TabsBox {
  width: 100%;
  height: 425px;
  box-shadow: 0 0 10px rgb(239 239 239 / 50%);
  box-sizing: border-box;
  background-color: #fff;
  padding: 10px 0 20px 0;
}
/* 服务流程 */
.service {
  width: 100%;
  height: 597px;
}
.service img {
  width: 100%;
  height: 100%;
}</style
>>
