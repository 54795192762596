<template>
  <div class="main">
    <div class="mainR">
      <div class="contents">
        <h2>关于我们</h2>
        <p class="contentp" v-html="content"></p>
        
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      img: "",
      content: "",
    };
  },
  methods: {},
  created() {
    this.$http
      .post("/stock_admin/api/article/queryArticleListByPage", {
        type: "about_us",
      })
      .then((res) => {
        (this.img = res.result.list[0].imgPath),
          (this.content = res.result.list[0].content);
      });
  },
};
</script>
<style>
.main {
  padding: 20px 0 30px;
  width: 100%;
  margin: 0 auto;
  background: #fff;
}
.mainR {
   width: 1220px;
  margin: 0 auto;
}
.contents {
  min-height: 400px;
  border: 1px solid #eaedec;
  padding: 30px 35px;
  line-height: 200%;
}
.contents h2 {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 20 px;
  color: #007bc7;
  text-align: center;
}
.contentp {
  width: 100%;
  overflow: hidden;
}
.contentp img{
  width: 1220px !important;

}
</style>
