<template>
  <el-carousel :interval="5000" arrow="never" autoplay height="592px" indicator-position="90px">
    <el-carousel-item v-for="item in SWiperList" :key="item.id">
      <img :src="item.bannerPicUrl" alt="" class="swiper" />
    </el-carousel-item>
  </el-carousel>
</template>
<script>
export default ({
  props:['SWiperList'],
  name: 'Swiper',
  data() {
    return {
      swiperList: [
        {
         img: 'http://www.hdczhuce.com/public/upload/20190604/ecee501e3ad20cc9f906ed9f98f46e5f.jpg',
          url: ''
        },
        {
         img: 'http://www.hdczhuce.com/public/upload/20190604/ecee501e3ad20cc9f906ed9f98f46e5f.jpg',
          url: ''
        },
        {
         img: 'http://www.hdczhuce.com/public/upload/20190604/ecee501e3ad20cc9f906ed9f98f46e5f.jpg',
          url: ''
        },
        {
        img: 'http://www.hdczhuce.com/public/upload/20190604/ecee501e3ad20cc9f906ed9f98f46e5f.jpg',
          url: ''
        }

      ]
    }
  }
})
</script>

<style>
.swiper {
  width: 100%;
  height: 100%;
}
.el-carousel__button{
 width: 60px;
 height: 4px;
}
.is-active button{
  background: #0d60a7 !important;
}
.el-carousel__indicators--horizontal{
  bottom:90px !important;
}

</style>