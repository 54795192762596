<template>
  <div>
    <div class="main-im">
      <div class="im_main" id="im_main">
        <div class="close-im" id="close-im">
          <a href="#" title="点击关闭"></a>
        </div>
        <a
          href="https://shang.qq.com//email/stop/email_stop.html"
          target="_blank"
          class="im-qq qq-a"
          title="在线QQ客服"
        >
          <div class="qq-container"></div>
          <div class="qq-hover-c">
            <img src="../assets/img/q1q.png" alt="" class="img-qq" />
          </div>
          <span> QQ在线咨询</span>
        </a>
        <div class="im-tel">
          <div>售前咨询QQ</div>
          <div class="tel-num">
           <a :href="FootObj.QQUrl" target="_blank">{{FootObj.coordinate}}</a>
          </div>
          <div>售后咨询QQ</div>
          <div class="tel-num">
          <a :href="FootObj.QQUrl" target="_blank">{{FootObj.coordinate}}</a>
          </div>
          <div class="ftewm">
            <img src="../assets/wx_12.jpg" alt="" class="weixing-ma" />
            <img src="../assets/wx_11.jpg" alt="" class="weixing-ma" />
            <div class="weixing-txt">微信扫一扫</div>
          </div>
        </div>
      </div>
    </div>
    <ins id="newBridge">
      <ins
        class="nb-nodeboard-base nb-nodeboard-position-base nb-nodeboard-left-bottom nb-show"
        id="nb_nodeboard"
        :style="{ left: leftSryle + 'px' }"
        v-if="ISshowMessage"
      >
        <ins class="nb-nodeboard-contain-base nb-nodeboard-contain">
          <ins
            id="nb_nodeboard_top"
            class="nb-nodeboard-top nb-nodeboard-top-1"
            style="background-color: transparent;"
          >
            <ins class="nb-head-title">留言板</ins>
            <ins
              class="nb-nodeboard-close"
              id="nb_nodeboard_close"
              data-type="min"
              @click="closeMessage"
            ></ins>
            <ins style="clear:both"></ins>
            <ins
              id="nb_nodeboard_text"
              class="nb-nodeboard-text"
              style="display:block;"
            >
              <p class="nb-nodeboard-company">{{FootObj.NameFour}}一站式企业服务平台</p>
              <p class="nb-nodeboard-link">{{FootObj.contactsPhone}}（微信同号）</p>
            </ins>
            <form
              id="nb_nodeboard_form"
              class="nb-board-form"
              style="width:240px;text-align:center;"
            >
              <ins id="nb_nodeboard_set" class="nb-nodeboard-set">
                <ins class="nb-nodeboard-content">
                  <textarea
                    id="nb-nodeboard-set-content-js"
                    v-model="content"
                    placeholder="请在此输入留言内容，我们会尽快与您联系。（必填）"
                    class="nb-nodeboard-set-content nb-nodeboard-param"
                  ></textarea>
                </ins>
              </ins>
              <ins
                class="nb-nodeboard-name"
                id="nb_nodeboard_phone"
                style="z-index:4;"
              >
                <ins class="nb-nodeboard-icon nodePhone"></ins>
                <input
                  id="nb_nodeboard_set_phone"
                  class="nb-nodeboard-input nb-nodeboard-param"
                  type=""
                  name=""
                  v-model="phone"
                  placeholder="电话（必填）"
                />

                <ins
                  class="nb-nodeboard-name"
                  id="nb_nodeboard_phone"
                  style="z-index:4;"
                >
                  <ins class="nb-nodeboard-icon nodePhone"></ins>
                  <input
                    id="nb_nodeboard_set_phone"
                    class="nb-nodeboard-input nb-nodeboard-param"
                    type=""
                    name=""
                    v-model="UserName"
                    placeholder="姓名（必填）"
                  />
                </ins>
              </ins>
              <ins
                class="nb-nodeboard-ext-select"
                style="z-index:1;margin-top:50px;"
              >
                <ins class="nb-nodeboard-select-title">主营业务</ins>
                <ins
                  class="nb-nodeboard-select-wrap"
                  id="node_select_wrap_1"
                  style="
    height: 30px;padding-left:40px;border:none"
                >
                  <select v-model="type" class="custom-select-title-content">
                    <option
                      value="服务类"
                      label="服务类"
                      selected="selected"
                    ></option>
                    <option value="贸易类" label="贸易类"></option>
                  </select>
                </ins>
              </ins>
              <ins class="nb-nodeboard-send" id="nb_node_contain">
                <ins
                  id="nb_nodeboard_send"
                  class="nb-nodeboard-send-btn nb-nodeboard-send-btn-1"
                  @click="sendMessage"
                  >发送</ins
                >
              </ins>
            </form>
          </ins>
        </ins>
      </ins>
      <ins
        class="
        nb-icon-wrap nb-icon-base
        icon-right-center
        nb-icon-skin-0 nb-icon-group
      "
        id="nb_icon_wrap"
        style="margin-top: -127px; width: 144px;z-index:999"
      >
        <ins class="nb-icon-group">
          <ins
            class="nb-icon-inner-wrap"
            style="height: 52px; width: 144px; border-radius: 0"
          >
            <ins
              class="nb-icon-bridge0 nb-icon-bridge-base"
              data-type="iconInvite"
            >
            </ins>
          </ins>
          <ins class="nb-icon-groups nb-show" style="margin-left: 0;">
            <ins
              class="nb-icon-groups-item icon-disable nb-clearfix"
              @click="seeMessage"
            >
              <ins class="nb-group-icon"> </ins>
              <ins class="nb-group-text">工商服务1</ins>
            </ins>
            <ins
              class="nb-icon-groups-item icon-disable nb-clearfix"
              @click="seeMessage"
            >
              <ins class="nb-group-icon"> </ins>
              <ins class="nb-group-text">工商服务2</ins>
            </ins>
            <ins
              class="nb-icon-groups-item icon-disable nb-clearfix"
              @click="seeMessage"
            >
              <ins class="nb-group-icon"> </ins>
              <ins class="nb-group-text">代理记账1</ins>
            </ins>
            <ins
              class="nb-icon-groups-item icon-disable nb-clearfix"
              @click="seeMessage"
            >
              <ins class="nb-group-icon"> </ins>
              <ins class="nb-group-text">代理记账2</ins>
            </ins>
          </ins>
        </ins>
      </ins>
      <ins
        class="nb-invite-wrap nb-invite-wrap-base nb-position-base nb-middle"
        style="width: 400px; display: block; margin-left: -200px; margin-top: -88px; z-index=999"
        v-if="MidSHOW"
      >
        <ins class="nb-invite-body nb-invite-skin-0" style="height:175px;">
          <ins
            class="nb-invite-tool nb-invite-tool-base"
            @click="closeThis"
          ></ins>
          <ins class="nb-invite-text nb-invite-text-base">
            <ins class="nb-invite-welcome nb-invite-welcome-base nb-show">
              <p style="color: #fff">欢迎来到本网站，请问有什么可以帮您？</p>
            </ins>
          </ins>
          <ins class="nb-invite-btn-base nb-invte-btns-0">
            <ins
              class="nb-invite-cancel nb-invite-cancel-base"
              @click="closeThis"
              >稍后再说</ins
            >
            <ins class="nb-invite-ok nb-invite-ok-base" @click="seeMessage"
              >现在咨询</ins
            >
          </ins>
        </ins>
      </ins>
    </ins>

    <el-container>
      <div class="main_top">
        <div class="main_top_middle">
          <div class="header">
            <div class="main_top_img">
              <a href="#">
                <div class="top_title">{{FootObj.companyName}}</div></a
              >
            </div>

            <nav class="site-nav">
              <div class="top_right_top_num"><span style="margin-right:10px">{{FootObj.companyTel}}</span>{{FootObj.contactsPhone}}</div>
              <ul>
                <li
                  class="nav_l"
                  v-for="(item, index) in menu_item"
                  :key="item.name"
                >
                  <div
                    :class="[{ selectedPic: selectedItemVal == item.id }, 'li']"
                    @click="goToProductOption(item.id,item.name)"
                  >
                    {{ item.name }}
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <el-main style="line-height: 0px">
        <router-view :key="$route.fullPath"></router-view>
      </el-main>
      <el-footer>
        <div class="bottom_02">
          <div class="inner">
            <div class="fl" style="padding-left: 33px">
              <ul>
                <li>
                  <span>{{ footList[0].dictName }}</span>
                  <a
                    href=""
                    v-for="(item, index) in footList[0].children"
                    :key="item.id"
                    @click.prevent="toDetail(3, index,footList[0].dictName)"
                    >{{ item.dictName }}</a
                  >
                </li>
                <li>
                  <span>{{ footList[1].dictName }}</span>
                  <a
                    href=""
                    v-for="(item, index) in footList[1].children"
                    :key="item.id"
                    @click.prevent="toDetail(4, index,footList[1].dictName)"
                    >{{ item.dictName }}</a
                  >
                </li>
                <li>
                  <span>{{ footList[2].dictName }}</span>
                  <a
                    href=""
                    v-for="(item, index) in footList[2].children"
                    :key="item.id"
                    @click.prevent="toDetail(5, index,footList[2].dictName)"
                    >{{ item.dictName }}</a
                  >
                </li>
              </ul>
            </div>
            <div class="fl wx">
              <p>公司官方微信</p>
              <div class="btm_ewm clearFix">
                <img
                  src="../assets/img/wx_11.jpg"
                  alt=""
                  style="float: left"
                /><img
                  src="../assets/img/wx_12.jpg"
                  alt=""
                  style="float: right"
                />
              </div>
              <div style="clear: both"></div>
            </div>

            <div class="fl content">
              <p class="phone">
                <span>{{FootObj.companyTel}}</span>
                <i>24小时客户服务热线</i>
              </p>
              <p class="online">
                <span>在线客服</span>
                <i> QQ：<a :href="FootObj.QQUrl" target="_blank">{{FootObj.coordinate}}</a>  </i>
              </p>
              <p class="coop">
                <span>商务合作</span>
                <i>{{FootObj.onlineUrl}}</i>
              </p>
            </div>
          </div>
        </div>
        <div class="main_bottom">
          <div class="main_bottom_middle">
            <div class="bottom_left">
              <img
                src="../assets/img/banquan.jpg"
                alt=""
                srcset=""
                class="bottom_img"
              />
              <p class="us_inf">
                Copyright © 2019 www
                <a href="" style="color: #999" class="a">{{FootObj.recordNumber}}</a
                >{{FootObj.companyName}}
              </p>
              <p class="us_inf">
                电子邮箱：{{FootObj.onlineUrl}}  在线客服
                QQ：<a :href="FootObj.QQUrl" target="_blank">{{FootObj.coordinate}}</a>  咨询电话：{{FootObj.companyTel}} 会计服务：<a :href="FootObj.QQUrl" target="_blank">{{FootObj.coordinate}}</a> 
              </p>
              <p class="us_inf">{{FootObj.companyAddress}}</p>
              <p class="us_inf">
                技术支持：<a href="https://www.shuxunanhe.com/" style="color: #999" class="a"
                  >山西数迅安和网络科技有限公司</a
                >
              </p>
            </div>
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      MidSHOW: false,
      active: "首页",
      isshow: false,
      menu_item: [
        { name: "首页", id: "1" },
        { name: "关于我们", id: "2" },
        { name: "工商注册", id: "3" },
        { name: "会计服务", id: "4" },
        // { name: "企业变更", id: "5" },
        { name: "商标代理", id: "5" },
        { name: "专项审批", id: "6" },
        { name: "法律咨询", id: "7" },
        { name: "劳务派遣", id: "8" },
        { name: "创业孵化", id: "9" },
      ],
      selectedItemVal: 0,
      a: 1,
      UserName: "",
      content: "",
      type: "",
      phone: "",
      ISshowMessage: true, //显示留言
      leftSryle: "20",
      footList:[],//底部菜单
      FootObj:{},//公司信息 底部内容
    };
  },
  provide() {
    return {
      say: this.say,
    };
  },
  methods: {
    seeMessage() {
      (this.ISshowMessage = true), (this.leftSryle = "800");
      this.MidSHOW = false;
    },
    closeMessage() {
      this.ISshowMessage = false;
    },
    closeThis() {
      this.MidSHOW = false;
    },
    // 提交留言信息
    sendMessage() {
      if (this.content == "") {
        return false;
      }
      if (this.phone == "") {
        return false;
      }
      if (this.UserName == "") {
        return false;
      }
      this.$http
        .post("/stock_admin/api/message/addMessage", {
          phone: this.phone,
          content: this.content,
          messageType: this.type,
          userName: this.UserName,
        })
        .then((res) => {
          this.UserName = "";
          this.content = "";
          this.type = "";
          this.phone = "";
        });
    },
    // 顶部点击事件
    goToProductOption(num,name) {
       sessionStorage.setItem('topName',name)
      // 主页
      if (num == 1) {
        sessionStorage.setItem("active", num);
        this.selectedItemVal = num;
        this.$router.push("/index");

        // 概况
      } else if (num == 2) {
        sessionStorage.setItem("active", num);
        this.selectedItemVal = num;
        this.$router.push({
          path: "aboutwe",
          query: { topTabsSelect: "" },
        });
        this.$router.push("/aboutwe");

        // 人才
      } else if (num == 3) {
        sessionStorage.setItem("active", num);
        this.selectedItemVal = num;
        this.$store.dispatch("SetTopTabsSelect", 0);
        this.$store.dispatch("SetTopTabsSelect", 0);
        this.$router.push({
          name: "CommonParts",
          query: { topTabsSelect: "0" },
        });

        // 天地
      } else if (num == 4) {
        sessionStorage.setItem("active", num);
        this.selectedItemVal = num;
        this.$store.dispatch("SetTopTabsSelect", 1);
        this.$store.dispatch("SetFootTabsSelect", 0);
        this.$store.dispatch("SetFootSeasonSelect", 0);
        this.$router.push({
          name: "CommonParts",
          query: { topTabsSelect: "1" },
        });

        // 资料
      } else if (num == 5) {
        sessionStorage.setItem("active", num);
        this.selectedItemVal = num;
        this.$store.dispatch("SetTopTabsSelect", 2);
        this.$store.dispatch("SetFootTabsSelect", 0);
        this.$router.push({
          name: "CommonParts",
          query: { topTabsSelect: "2" },
        });

        // 商城
      } else if (num == 6) {
        sessionStorage.setItem("active", num);
        this.selectedItemVal = num;
        this.$store.dispatch("SetTopTabsSelect", 3);
        this.$store.dispatch("SetFootTabsSelect", 0);
        this.$router.push({
          name: "CommonParts",
          query: { topTabsSelect: "3" },
        });

        // 我们
      } else if (num == 7) {
        sessionStorage.setItem("active", num);
        this.selectedItemVal = num;
        this.$store.dispatch("SetTopTabsSelect", 4);
        this.$store.dispatch("SetFootTabsSelect", 0);
        this.$router.push({
          name: "CommonParts",
          query: { topTabsSelect: "4" },
        });
      } else if (num == 8) {
        sessionStorage.setItem("active", num);
        this.selectedItemVal = num;
        this.$store.dispatch("SetTopTabsSelect", 5);
        this.$store.dispatch("SetFootTabsSelect", 0);
        this.$router.push({
          name: "CommonParts",
          query: { topTabsSelect: "5" },
        });
      } else if (num == 9) {
        sessionStorage.setItem("active", num);
        this.selectedItemVal = num;
        this.$store.dispatch("SetTopTabsSelect", 6);
        this.$store.dispatch("SetFootTabsSelect", 0);
        this.$router.push({
          name: "CommonParts",
          query: { topTabsSelect: "6" },
        });
      }
     
    },
    changeActive() {
      this.isshow = true;
    },
    // 请求字典
    getTabsList() {
      this.$http.get("/stock_admin/api/dict/queryAll").then((res) => {
        let arr = res.result;
        let err = [
          { name: "工商注册", id: "3" },
          { name: "会计服务", id: "4" },
          { name: "商标代理", id: "5" },
          { name: "专项审批", id: "6" },
          { name: "法律咨询", id: "7" },
          { name: "劳务派遣", id: "8" },
          { name: "创业孵化", id: "9" },
        ];
        let footList = [
          { name: "法律咨询", id: "7" },
          { name: "劳务派遣", id: "8" },
          { name: "创业孵化", id: "9" },
        ];
        for (let i = 0; i < arr.length; i++) {
          for (let q = 0; q < err.length; q++) {
            if (err[q].name == arr[i].dictName) {
              err[q] = arr[i];
            }
          }
        }
        for (let i = 0; i < arr.length; i++) {
          for (let j = 0; j < footList.length; j++) {
            if (footList[j].name == arr[i].dictName) {
              footList[j] = arr[i];
            }
          }
        }
        this.footList = footList
        this.$store.dispatch("SetALLtabsList", JSON.stringify(err));
        sessionStorage.setItem("ALLtabsList", JSON.stringify(err));
        sessionStorage.setItem("active", 0);
        this.selectedItemVal = 0;
      });
    },
    // 获取内容信息
    getContentList() {
      this.$http
        .post("/stock_admin/api/article/queryArticleListByPage", {
          type: "news",
        })
        .then((res) => {
          this.$store.dispatch(
            "SetContentList",
            JSON.stringify(res.result.list)
          );
          sessionStorage.setItem(
            "ContentList",
            JSON.stringify(res.result.list)
          );
        });
    },
    getFootObj(){
      this.$http.get('/stock_admin/api/wineCompany/selectBaseCompany').then(res=>{
         res.result.QQUrl = `http://wpa.qq.com/msgrd?v=3&uin=${res.result.coordinate}&site=qq&menu=yes`
         res.result.NameFour = res.result.companyName.substr(0,4)
         this.FootObj = res.result
      })
    },
    getTopImgList(){
       this.$http
        .post("/stock_admin/api/article/queryArticleListByPage", {
          type: "contact_us",
        })
        .then((res) => {
         
          this.$store.dispatch(
            "SetTopImgList",
            JSON.stringify(res.result.list)
          );
          sessionStorage.setItem(
            "TopImgList",
            JSON.stringify(res.result.list)
          );
        });
    },
    removeActive() {
      this.isshow = false;
    },
    say() {
      this.selectedItemVal = sessionStorage.getItem("active");
    },
    //底部跳转
    toDetail(i, a,name) {
      sessionStorage.setItem('topName',name)
      sessionStorage.setItem("active", i + 3);
      this.selectedItemVal = i + 3;
      this.$store.dispatch("SetTopTabsSelect", i);
      this.$store.dispatch("SetTopTabsSelect", a);
      this.$router.push({
        name: "CommonParts",
        query: { topTabsSelect: i },
      });
    },
  },
  created() {
    this.getTopImgList()
    this.getFootObj()
    this.getTabsList();
    this.getContentList();
    // sessionStorage.setItem("active", "1");
    this.selectedItemVal = sessionStorage.getItem("active") || 1;
    // if (this.selectedItemVal == 1) {
    //   this.$router.push("/NoticeNotice");
    //   sessionStorage.setItem("active", num);
    //   this.selectedItemVal = num;
    // }
    this.$bus.$on("tabsQH", (e) => {
      this.selectedItemVal = e + 3;
    });
    window.setInterval(() => {
      setTimeout(() => {
        this.MidSHOW = true;
      }, 0);
    }, 30000);
  },
};
</script>

<style>
html {
  font-family: SimSun !important;
}
.selectedPic {
  border-bottom: 3px solid #0098e0;
  color: #0098e0;
}

.header {
  width: 100%;
  height: 100%;
  margin: auto;
}
.main_top {
  width: 100%;
  background-color: #ebebeb;
}
.main_top_middle {
  width: 1220px;
  margin: auto;
}
.main_top_img {
  width: 35%;
  float: left;
}
.top_title {
  font-size: 28px;
  line-height: 109px;
  color: #000;
  text-align: left;
}
.site-nav {
  width: 62%;
  height: 43px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  float: right;
  padding-top: 58px;
  position: relative;
}

ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.nav_l {
  width: 100%;
  position: relative;
  cursor: pointer;
  text-align: center;
  float: left;
  display: block;
  padding: 0 0px;
  line-height: 43px;
  color: #333;
  margin-right: 3px;
  color: #333;
}
.nav_l .li {
  font-size: 16px;
  height: 43px;
}
.nav_l .li:hover {
  border-bottom: 2px solid #0098e0;
  color: #0098e0;
}
a{
  color: #949494 !important;
}
.nav_l a {
  position: relative;
  display: flex;
  color: #000;
  font-family: SimSun !important;
  text-decoration: none;
  transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  font-size: 14px;
}
.top_right_top_num {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 20px;
  line-height: 50px;
  text-align: left;
  font-weight: 500;
  font-family: "Microsoft YaHei", 微软雅黑, 黑体, 宋体, "Microsoft JhengHei";
}
/* ----- */
.title {
  line-height: 60px;
}
.el-main {
  padding: 0 !important;
  background-color: #ebebeb !important;
}
/* bottom */
.el-footer {
  background-color: #fff;
  padding: 0 !important;
  height: 145px !important;
}
.main_bottom {
  width: 100%;
  height: 110px;
  background-color: #2d2d2d;
  padding-top: 35px;
}
.main_bottom_middle {
  width: 1220px;
  margin: auto;
}
.bottom_left {
  width: 100%;
  position: relative;
}

.bottom_img {
  position: absolute;
  left: 0;
  top: 0;
}
.us_inf {
  line-height: 16px;
  font-size: 12px;
  color: #999;
  margin: 0px;
  margin-bottom: 10px;
  text-align: left;
  margin-left: 42px;
}
.us_inf .a:hover {
  color: #fff !important;
}

/* bottom */

.bottom_inf {
  width: 100%;
  color: #ffffff;
  opacity: 0.6;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 33px;
}
.b_inf_text {
  width: 100%;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
}
.bottom_02 {
  height: 231px;
  background-color: #f4f2f2;
  padding-top: 40px;
}
.inner {
  width: 1220px;
  margin: auto;
}
.fl {
  float: left;
}
.fl ul li {
  width: 200px;
  float: left;
}
.fl ul li span {
  display: block;
  font-size: 16px;
  color: #625e5f;
  padding-bottom: 15px;
  font-weight: 800;
  text-align: left;
}
.fl ul li a {
  display: block;
  font-size: 12px;
  color: #3b3536;
  line-height: 28px;
  text-align: left;
}
.fl ul li a:hover {
  color: #0d60a7 !important;
}
.wx {
  width: 280px;
  margin-right: 57px;
}
.wx p {
  font-size: 16px;
  color: #625e5f;
  font-weight: 800;
  margin-bottom: 20px;
  text-align: left;
  margin-top: 0px;
}
.btm_ewm {
  width: 280px;
  margin-right: 57px;
  /* display: flex; */
  /* justify-content: space-between; */
}
.btm_ewm img {
  display: block;
  width: 120px;
  height: 120px;
}
.content {
  float: left;
}
.content p {
  height: 38px;
  padding-left: 44px;
  margin-bottom: 18px;
  text-align: left;
}
.content p span {
  display: block;
  font-size: 14px;
  color: #373233;
  margin-bottom: 7px;
}
.content p i {
  display: block;
  font-size: 12px;
  color: #949494;
  font-style: normal;
}
.phone {
  background: url(../assets/img/icon_11.jpg) no-repeat 3px 2px;
}
.online {
  background: url(../assets/img/icon_18.jpg) no-repeat 3px 2px;
}
.coop {
  background: url(../assets/img/icon_23.jpg) no-repeat 3px 2px;
}

/* 悬浮——left */
.main-im {
  position: fixed;
  left: 38px;
  top: 230px;
  z-index: 100;
  width: 110px;
}
.main-im .im_main {
  background: #f9fafb;
  border: 1px solid #dddddd;
  border-radius: 10px;
  background: #f9fafb;
}
.main-im .close-im {
  position: absolute;
  right: 10px;
  top: -12px;
  z-index: 100;
  width: 24px;
  height: 24px;
}
.main-im .close-im a {
  display: block;
  width: 24px;
  height: 24px;
  background: url(../assets/img/close_im.png) no-repeat left top;
}
.main-im .qq-a {
  display: block;
  width: 106px;
  height: 116px;
  font-size: 14px;
  color: #0484cd;
  text-align: center;
  position: relative;
}
.main-im .qq-container {
  z-index: 99;
  position: absolute;
  width: 109px;
  height: 118px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid #dddddd;
  background: url(../assets/img/qq-icon-bg.png) no-repeat center 8px;
}
.main-im .qq-hover-c {
  width: 70px;
  height: 70px;
  border-radius: 35px;
  position: absolute;
  left: 18px;
  top: 10px;
  overflow: hidden;
  z-index: 9;
}
.main-im .img-qq {
  max-width: 60px;
  display: block;
  position: absolute;
  left: 6px;
  top: 3px;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.main-im .qq-a span {
  bottom: 5px;
  position: absolute;
  width: 90px;
  left: 10px;
}
.main-im .im_main .im-tel {
  color: #000000;
  text-align: center;
  width: 109px;
  /* height: 105px; */
  border-bottom: 1px solid #dddddd;
}
.main-im .im_main .im-tel div {
  font-weight: bold;
  font-size: 12px;
  margin-top: 6px;
}
.main-im .im_main .im-tel .tel-num a {
  font-weight: bold;
  color: #e66d15 !important;
}
.ftewm img {
  width: 90px;
  display: block;
  margin: 10px auto 0;
}
img {
  border: 0;
}
.ftewm .weixing-txt {
  line-height: 30px;
  font-size: 14px;
  text-align: center;
}
/* 右侧悬浮窗 */
#newBridge {
  font-family: Arial, Helvetica, "Microsoft YaHei", sans-serif;
  position: fixed;
  bottom: 160px !important;
  top: inherit !important;
  right: 38px !important;
  z-index: 2147483647;
}
#newBridge .icon-right-center {
  /* right: 2px;
  top: 50%;
  left: auto;
  bottom: auto; */
  margin: -25px 0 0 0;
}

#newBridge .nb-icon-group {
  border-radius: 0;
}
#newBridge .nb-icon-base {
  position: fixed;
  cursor: pointer;
  border-radius: 5px;
}
#newBridge .icon-right-center {
  bottom: 160px !important;
  top: inherit !important;
  right: 38px !important;
}
#newBridge ins {
  text-decoration: none;
}
#newBridge .nb-icon-group {
  border-radius: 0;
}

#newBridge .nb-icon-skin-0 .nb-icon-inner-wrap {
  background-color: #00aef3;
}
#newBridge .nb-icon-base .nb-icon-inner-wrap {
  position: relative;
  overflow: hidden;
}

#newBridge .nb-icon-skin-0 .nb-icon-inner-wrap .nb-icon-bridge-base {
  background: transparent
    url(//sgoutong.baidu.com/embed/1632285737/asset/embed/css/pc/icon/img/iconWord0.png)
    no-repeat;
}
#newBridge .nb-icon-skin-0 .nb-icon-bridge0 {
  height: 50px;
  width: 140px;
  margin-left: -68px;
  margin-top: -25px;
  background: transparent
    url(//sgoutong.baidu.com/embed/1632285737/asset/embed/css/pc/icon/img/iconWord0.png)
    no-repeat;
}
#newBridge .nb-icon-base .nb-icon-bridge-base {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
}
#newBridge .nb-icon-group {
  border-radius: 0;
}

#newBridge .nb-icon-skin-0 .nb-icon-groups {
  background-color: #fff;
  border: 1px solid #00aef3;
}
#newBridge .nb-icon-group .nb-icon-groups {
  position: relative;
  overflow: hidden;
}
#newBridge .nb-show {
  display: block;
}
#newBridge .nb-icon-groups {
  border-top: 0 !important;
}

#newBridge .nb-icon-group .icon-disable {
  background-color: #f2f2f2;
  border-color: #848484;
}

#newBridge .nb-icon-skin-0 .nb-icon-groups-item {
  /* background-color: #d6f3ff; */
  border: 1px solid #00aef3;
}
#newBridge .nb-icon-group .nb-clearfix {
  zoom: 1;
}
#newBridge .nb-icon-group .nb-icon-groups-item {
  cursor: pointer;
  margin: 10px 10px;
  border-radius: 16px;
}

#newBridge .nb-icon-group .icon-disable .nb-group-icon {
  background: transparent
    url(//sgoutong.baidu.com/embed/1632285737/asset/embed/css/pc/icon/img/icondisable.png)
    no-repeat;
}

#newBridge .nb-icon-group .nb-group-icon {
  width: 31px;
  height: 31px;
  display: inline-block;
  zoom: 1;
  display: inline;
  vertical-align: middle;
  float: left;
}

#newBridge .nb-icon-group .icon-disable .nb-group-text {
  color: #848484;
}
/* #newBridge .nb-icon-skin-0 .nb-icon-groups-item .nb-group-text {
  color: #008edf;
} */

#newBridge .nb-icon-group .nb-group-text {
  height: 31px;
  line-height: 31px;
  vertical-align: middle;
  margin-left: 38px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-overflow: clip;
  width: 60%;
  overflow: hidden;
}
#newBridge,
#nbInviteWrapper,
#nbWebImLightContainer,
#newBridge ins,
#nbInviteWrapper ins,
#nbWebImLightContainer ins,
#newBridge a,
#nbInviteWrapper a,
#nbWebImLightContainer a,
#newBridge div,
#nbInviteWrapper div,
#nbWebImLightContainer div,
#newBridge p,
#nbInviteWrapper p,
#nbWebImLightContainer p,
#newBridge em,
#nbInviteWrapper em,
#nbWebImLightContainer em,
#newBridge b,
#nbInviteWrapper b,
#nbWebImLightContainer b,
#newBridge dd,
#nbInviteWrapper dd,
#nbWebImLightContainer dd,
#newBridge dl,
#nbInviteWrapper dl,
#nbWebImLightContainer dl,
#newBridge ul,
#nbInviteWrapper ul,
#nbWebImLightContainer ul,
#newBridge li,
#nbInviteWrapper li,
#nbWebImLightContainer li,
#newBridge i,
#nbInviteWrapper i,
#nbWebImLightContainer i,
#newBridge h1,
#nbInviteWrapper h1,
#nbWebImLightContainer h1,
#newBridge h2,
#nbInviteWrapper h2,
#nbWebImLightContainer h2,
#newBridge h3,
#nbInviteWrapper h3,
#nbWebImLightContainer h3,
#newBridge h4,
#nbInviteWrapper h4,
#nbWebImLightContainer h4,
#newBridge h5,
#nbInviteWrapper h5,
#nbWebImLightContainer h5,
#newBridge span,
#nbInviteWrapper span,
#nbWebImLightContainer span,
#newBridge table,
#nbInviteWrapper table,
#nbWebImLightContainer table,
#newBridge td,
#nbInviteWrapper td,
#nbWebImLightContainer td,
#newBridge tr,
#nbInviteWrapper tr,
#nbWebImLightContainer tr,
#newBridge tbody,
#nbInviteWrapper tbody,
#nbWebImLightContainer tbody,
#newBridge thead,
#nbInviteWrapper thead,
#nbWebImLightContainer thead,
#newBridge strong,
#nbInviteWrapper strong,
#nbWebImLightContainer strong,
#newBridge u,
#nbInviteWrapper u,
#nbWebImLightContainer u,
#newBridge img,
#nbInviteWrapper img,
#nbWebImLightContainer img,
#newBridge font,
#nbInviteWrapper font,
#nbWebImLightContainer font,
#newBridge select,
#nbInviteWrapper select,
#nbWebImLightContainer select,
#newBridge option,
#nbInviteWrapper option,
#nbWebImLightContainer option,
#newBridge input,
#nbInviteWrapper input,
#nbWebImLightContainer input,
#newBridge form,
#nbInviteWrapper form,
#nbWebImLightContainer form,
#newBridge textarea,
#nbInviteWrapper textarea,
#nbWebImLightContainer textarea {
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  background-color: transparent;
  background-position: 0 0;
  background-size: auto;
  background-repeat: repeat;
  background-origin: padding-box;
  background-clip: border-box;
  background-attachment: scroll;
  background-image: none;
  border: none;
  outline: invert none medium;
  direction: ltr;
  letter-spacing: inherit;
  line-height: normal;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  text-transform: none;
  white-space: inherit;
  word-spacing: normal;
  font-size: 12px;
  font-family: inherit;
  font-size-adjust: none;
  font-stretch: normal;
  font-style: inherit;
  font-variant: normal;
  font-weight: inherit;
  visibility: visible;
  overflow: visible;
  list-style: none;
  float: none;
  clear: none;
  display: block;
  position: static;
  top: auto;
  left: auto;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
#newBridge .nb-show {
  display: block;
}

#newBridge .nb-nodeboard-position-base {
  position: fixed;
  bottom: 0;
}
#newBridge .nb-nodeboard-base {
  width: 240px;
  height: 400px;
  border-radius: 8px;
  z-index: 3;
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.4);
  background-color: #fff;
  text-align: center;
}
#newBridge ins {
  display: block;
  text-decoration: none;
  overflow: hidden;
  margin: 0 auto;
}
#newBridge .nb-nodeboard-base .nb-nodeboard-contain-base {
  color: #000;
  border-radius: 8px;
  background-color: #fff;
}
#newBridge ins {
  display: block;
  text-decoration: none;
}
#newBridge,
#nbInviteWrapper,
#nbWebImLightContainer,
#newBridge ins,
#nbInviteWrapper ins,
#nbWebImLightContainer ins,
#newBridge a,
#nbInviteWrapper a,
#nbWebImLightContainer a,
#newBridge div,
#nbInviteWrapper div,
#nbWebImLightContainer div,
#newBridge p,
#nbInviteWrapper p,
#nbWebImLightContainer p,
#newBridge em,
#nbInviteWrapper em,
#nbWebImLightContainer em,
#newBridge b,
#nbInviteWrapper b,
#nbWebImLightContainer b,
#newBridge dd,
#nbInviteWrapper dd,
#nbWebImLightContainer dd,
#newBridge dl,
#nbInviteWrapper dl,
#nbWebImLightContainer dl,
#newBridge ul,
#nbInviteWrapper ul,
#nbWebImLightContainer ul,
#newBridge li,
#nbInviteWrapper li,
#nbWebImLightContainer li,
#newBridge i,
#nbInviteWrapper i,
#nbWebImLightContainer i,
#newBridge h1,
#nbInviteWrapper h1,
#nbWebImLightContainer h1,
#newBridge h2,
#nbInviteWrapper h2,
#nbWebImLightContainer h2,
#newBridge h3,
#nbInviteWrapper h3,
#nbWebImLightContainer h3,
#newBridge h4,
#nbInviteWrapper h4,
#nbWebImLightContainer h4,
#newBridge h5,
#nbInviteWrapper h5,
#nbWebImLightContainer h5,
#newBridge span,
#nbInviteWrapper span,
#nbWebImLightContainer span,
#newBridge table,
#nbInviteWrapper table,
#nbWebImLightContainer table,
#newBridge td,
#nbInviteWrapper td,
#nbWebImLightContainer td,
#newBridge tr,
#nbInviteWrapper tr,
#nbWebImLightContainer tr,
#newBridge tbody,
#nbInviteWrapper tbody,
#nbWebImLightContainer tbody,
#newBridge thead,
#nbInviteWrapper thead,
#nbWebImLightContainer thead,
#newBridge strong,
#nbInviteWrapper strong,
#nbWebImLightContainer strong,
#newBridge u,
#nbInviteWrapper u,
#nbWebImLightContainer u,
#newBridge img,
#nbInviteWrapper img,
#nbWebImLightContainer img,
#newBridge font,
#nbInviteWrapper font,
#nbWebImLightContainer font,
#newBridge select,
#nbInviteWrapper select,
#nbWebImLightContainer select,
#newBridge option,
#nbInviteWrapper option,
#nbWebImLightContainer option,
#newBridge input,
#nbInviteWrapper input,
#nbWebImLightContainer input,
#newBridge form,
#nbInviteWrapper form,
#nbWebImLightContainer form,
#newBridge textarea,
#nbInviteWrapper textarea,
#nbWebImLightContainer textarea {
  width: auto;
  height: auto;
  margin: 0;
  background-position: 0 0;
  background-size: auto;
  background-repeat: repeat;
  background-origin: padding-box;
  background-clip: border-box;
  background-attachment: scroll;
  background-image: none;
  border: none;
  direction: ltr;
  letter-spacing: inherit;
  line-height: normal;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  text-transform: none;
  white-space: inherit;
  word-spacing: normal;
  font-size: 12px;
  font-family: inherit;
  font-stretch: normal;
  font-style: inherit;
  font-variant: normal;
  font-weight: inherit;
  visibility: visible;
  overflow: visible;
  list-style: none;
  float: none;
  clear: none;
  display: block;
  position: static;
  top: auto;
  left: auto;

  box-sizing: content-box;
}
#newBridge .nb-nodeboard-base .nb-nodeboard-contain-base .nb-nodeboard-top {
  height: 46px;
  line-height: 46px;
  font-size: 14px;
  background-color: #4e6ef2;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

#newBridge .nb-nodeboard-base .nb-nodeboard-top-1 {
  background: #4e6ef2
    url(//sgoutong.baidu.com/embed/1632285737/asset/embed/css/pc/message/img/boardTop1.png)
    no-repeat;
  background-size: 100% 100%;
}
#newBridge ins {
  display: block;
  text-decoration: none;
}
#newBridge .nb-nodeboard-base .nb-nodeboard-contain-base .nb-head-title {
  float: left;
  font-size: 14px;
  color: #fff;
  margin-left: 12px;
  line-height: 46px;
  padding-left: 2px;
}

#newBridge ins {
  display: block;
  text-decoration: none;
}
#newBridge .nb-nodeboard-base .nb-nodeboard-contain-base .nb-nodeboard-close {
  display: inline-block;
  float: right;
  height: 46px;
  width: 34px;
  cursor: pointer;
  background: transparent
    url(//sgoutong.baidu.com/embed/1632285737/asset/embed/css/pc/message/img/nodeMin.png)
    no-repeat;
  background-position: 0 center;
  background-size: 50%;
}
#newBridge ins {
  display: block;
  text-decoration: none;
}
#newBridge .nb-nodeboard-base .nb-nodeboard-contain-base .nb-nodeboard-text {
  zoom: 1;
  padding: 12px 0;
}
#newBridge ins {
  display: block;
  text-decoration: none;
}
#newBridge
  .nb-nodeboard-base
  .nb-nodeboard-contain-base
  .nb-nodeboard-text
  .nb-nodeboard-company {
  font-size: 14px;
  background-color: transparent;
  color: #303133;
}
#newBridge .nb-nodeboard-base .nb-nodeboard-contain-base .nb-nodeboard-text p {
  word-break: break-all;
  line-height: 18px;
  margin: 0 8px 0 12px;
}
#newBridge
  .nb-nodeboard-base
  .nb-nodeboard-contain-base
  .nb-nodeboard-text
  .nb-nodeboard-link {
  background-color: transparent;
  color: #909399;
  height: 12px;
}
#newBridge .nb-nodeboard-base .nb-nodeboard-contain-base .nb-nodeboard-text p {
  word-break: break-all;
  line-height: 18px;
  margin: 0 8px 0 12px;
}
#newBridge .nb-nodeboard-base .nb-nodeboard-contain-base .nb-board-form {
  display: inline;
  background-color: #ededed;
}
#newBridge ins {
  display: block;
  text-decoration: none;
}
#newBridge
  .nb-nodeboard-base
  .nb-nodeboard-contain-base
  .nb-board-form
  .nb-nodeboard-content {
  position: relative;
  width: 216px;
  margin: 8px auto;
}
#newBridge ins {
  display: block;
  text-decoration: none;
}
#newBridge
  .nb-nodeboard-base
  .nb-nodeboard-contain-base
  .nb-board-form
  .nb-nodeboard-set-content {
  width: 192px;
  height: 58px;
  margin: 12px auto 7px;
  /* margin: 12px 0 7px -10px; */
  padding: 10px 12px;
  resize: none;
  overflow: hidden;
  border: 1px solid #dcdfe6;
  background-color: #fff;
  text-align: left;
  line-height: 12px;
  outline: 0;
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
}
#newBridge
  .nb-nodeboard-base
  .nb-nodeboard-contain-base
  .nb-board-form
  .nb-nodeboard-name {
  height: 33px;
  width: 215px;
  line-height: 32px;
  margin: 8px auto 0;

  border: 1px solid #dcdfe6;
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  font-size: 14px;
}
#newBridge
  .nb-nodeboard-base
  .nb-nodeboard-contain-base
  .nb-board-form
  .nb-nodeboard-icon {
  width: 35px;
  height: 32px;
  display: inline-block;
  text-align: center;
  background-color: #fff;
  vertical-align: top;
  border-right: 1px solid #fff;
  border-radius: 4px 0 0 4px;
}
#newBridge .nodePhone {
  background: #fff
    url(//sgoutong.baidu.com/embed/1632285737/asset/embed/css/pc/message/img/nodephone.png)
    no-repeat 9px center;
}
#newBridge
  .nb-nodeboard-base
  .nb-nodeboard-contain-base
  .nb-board-form
  .nb-nodeboard-input {
  margin: 0;
  width: 172px;
  height: 32px;
  line-height: 32px;
  overflow: hidden;
  border: none;
  display: inline-block;
  outline: 0;
}

#newBridge
  .nb-nodeboard-base
  .nb-nodeboard-contain-base
  .nb-board-form
  .nb-nodeboard-ext-select {
  width: 215px;
  margin: 0 auto;

  position: relative;
  display: flex;
  align-items: center;
}
#newBridge
  .nb-nodeboard-base
  .nb-nodeboard-contain-base
  .nb-board-form
  .nb-nodeboard-select-title {
  color: #606266;
  height: 32px;
  line-height: 32px;
  background: #fff
    url(//sgoutong.baidu.com/embed/1632285737/asset/embed/css/pc/message/img/custom-select.png)
    no-repeat 10px center;
  background-color: transparent;
  padding: 0 20px 0 36px;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: none;
  outline: none;
  overflow: hidden;
  margin: 2px 0;
  font-size: 12px;
}
.custom-select-title-content {
  color: #8a8c8d;

  height: 30px;
  line-height: 30px;
  /* background: url(widget/img/custom-select.png) no-repeat 9px center; */
  background-image: url(//sgoutong.baidu.com/embed/1632285737/asset/embed/css/pc/widget/img/custom-select.png);
  padding: 0 20px 0 34px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#node_select_wrap_1 {
  color: #8a8c8d;
  height: 30px;
  line-height: 30px;

  background-image: url(//sgoutong.baidu.com/embed/1632285737/asset/embed/css/pc/widget/img/custom-select.png)
    no-repeat !important;
  padding: 0 20px 0 34px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #606266;
  height: 32px;
  line-height: 32px;
  background: #fff
    url(//sgoutong.baidu.com/embed/1632285737/asset/embed/css/pc/message/img/custom-select.png)
    no-repeat 10px center;
  background-color: transparent;
  padding: 0 20px 0 36px;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: none;
  outline: none;
  overflow: hidden;
  margin: 2px 0;
  font-size: 12px;
}
#newBridge .nb-nodeboard-base .nb-nodeboard-contain-base .nb-nodeboard-send {
  width: 218px;
  margin: 12px auto 0 10px;
  padding-bottom: 12px;
  margin-left: 10px;
  overflow: hidden;
}
#newBridge
  .nb-nodeboard-base
  .nb-nodeboard-contain-base
  .nb-nodeboard-send-btn {
  height: 32px;
  width: 64px;
  line-height: 32px;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  float: right;
  background-color: #4e6ef2;
  margin-right: 20px;
}
#nb_nodeboard {
  right: auto;
  top: auto;
  bottom: 340px;
  margin-left: 0px;
  margin-top: 0px;
  height: auto;
  z-index: 999 !important;
}
#newBridge .nb-middle {
  top: 50%;
  left: 50%;
  bottom: auto;
  right: auto;
  margin: -80px 0 0 -230px;
}
#newBridge .nb-position-base {
  position: fixed;
}
#newBridge .nb-invite-wrap-base {
  background: no-repeat;
  border-radius: 3px;
  overflow: hidden;
}
#newBridge .nb-invite-wrap {
  color: #000;
  z-index: 999;
}
#newBridge .nb-invite-skin-0 {
  display: block;
  background: #0085da url(../assets/img/inviteBack0.png) no-repeat;
}
#newBridge .nb-invite-body {
  position: relative;
  overflow: hidden;
}
#newBridge .nb-invite-wrap-base .nb-invite-tool-base {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 8px;
  top: 8px;
  left: auto;
  bottom: auto;
  cursor: pointer;
  background: transparent url(../assets/img/close.png) no-repeat;
}
#newBridge .nb-invite-wrap .nb-invite-text {
  position: absolute;
  top: 44px;
  left: 128px;
  right: 26px;
  font-size: 13px;
  color: #fff;
}
#newBridge .nb-invite-wrap-base .nb-invite-text-base .nb-invite-welcome-base,
#newBridge .nb-invite-wrap-base .nb-invite-text-base p {
  margin: 0;
  font-size: 13pt;
  color: #fff;
  word-break: break-all;
}
#newBridge .nb-invite-wrap-base .nb-invite-text-base .nb-invite-welcome-base,
#newBridge .nb-invite-wrap-base .nb-invite-text-base p {
  margin: 0;
  font-size: 13pt;
  color: #fff;
  word-break: break-all;
}
#newBridge .nb-invite-wrap-base .nb-invite-btn-base {
  position: absolute;
  bottom: 12px;
  right: 12px;
  top: auto;
  left: auto;
}
#newBridge .nb-invite-wrap-base .nb-invite-cancel-base {
  display: inline-block;
  width: 80px;
  height: 32px;
  line-height: 32px;
  color: #000;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 13px;
  color: #fff;
  background-color: #fff;
}
#newBridge .nb-invite-wrap-base .nb-invite-ok-base {
  display: inline-block;
  width: 80px;
  height: 32px;
  line-height: 32px;
  color: #000;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 13px;
  color: #0085da;
  margin-left: 8px;
}
#newBridge .nb-invite-skin-0 .nb-invite-ok {
  color: #0085da;
  background-color: #fff;
}
#newBridge .nb-invite-skin-0 .nb-invite-cancel {
  background-color: transparent;
}
</style>
