import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import Index from '../views/Index.vue'
import AboutWe from '../views/AboutWe.vue'
import CommonParts from '../views/CommonParts.vue'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    meta: {
      title: "主页",
    },
    redirect: '/index',
    children: [
      {
        path:'/index',
        name:'Index',
        component:Index,
        meta: {
          title: "主页",
        },
       
      },
      {
        path:'/aboutwe',
        name:'AboutWe',
        component:AboutWe,
        meta: {
          title: "关于我们",
        },
      
      },
      {
        path:'/commonparts',
        name:'CommonParts',
        component:CommonParts,
        meta: {
          title: "工商注册",
        },
      
      }
    ]
  }
]

const router = new VueRouter({
  routes
 
})

export default router
