<template>
  <div class="customer">
    <img src="../../assets/img/customer.jpg" alt="" />
    <div class="linner">
      <h2>客户眼中得我们</h2>
      <div class="lineL line"></div>
      <div class="lineR line"></div>
      <div style="height: 205px"></div>
      <el-carousel
        indicator-position="none"
        height="150px"
        arrow="always"
        class="carousel"
      >
        <el-carousel-item v-for="item in ItemList" :key="item.id">
          <div class="swiperMY">
            <p class="swiperMYP1">
              {{item.remark}}
            </p>
            <p class="swiperMYP2">— —{{item.title}} </p>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
export default {
 props:['ItemList']
}
</script>
<style>

.customer {
  width: 100%;
  height: 500px;
  position: relative;
}
.customer img {
  width: 100%;
  height: 100%;
}
.customer h2 {
  font-size: 28px;
  color: #fff;
  text-align: center;

  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}
.customer .line {
  width: 484px;
  height: 1px;
  background-color: #818589;
  position: absolute;
  top: 58px;
}
.lineL {
  left: 0;
}
.lineR {
  right: 0;
}
.linner {
  width: 1220px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.carousel {
  height: 150px;
}
.el-carousel__arrow {
  font-size: 40px !important;
}
.swiperMYP1 {
  font-size: 20px;
  color: #fff;
  line-height: 50px;
  white-space: 30px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.swiperMY {
  width: 800px;
  margin: 0 auto;
  height: 150px;
}
.swiperMYP2 {
  width: 100%;
  text-align: right !important;
  font-size: 16px;
  color: #fff;
}
</style>