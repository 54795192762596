import axios from "axios";

// http request 拦截器
axios.interceptors.request.use(
    (config) => {
        // let token = sessionStorage.getItem('token')
        // const token = "  eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJISUdIV0FZIiwidXNlclR5cGUiOjEsImV4cCI6MTYxMzI5NDA0MywidXNlcm5hbWUiOiJhZG1pbiJ9.ZawQITmkrfs62jns8Rff58fuSUHWaq2XWOCwQO5HX04"
        // if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
        // config.headers.Authorization = token  //请求头加上token
        // config.headers.Authorization = token  //请求头加上token
        // config.headers['X-Access-Token'] = token;
        // config.headers['Access-Control-Allow-Origin'] = "";
        // }
        return config
    },
    err => {
        return Promise.reject(err)
    })
// http response 拦截器
axios.interceptors.response.use(
    response => {
        //拦截响应，做统一处理 
        // if (response.data.code) {
        //   switch (response.data.code) {
        //     case 1002:
        //       store.state.isLogin = false
        //       router.replace({
        //         path: '/',
        //         query: {
        //           redirect: router.currentRoute.fullPath
        //         }
        //       })
        //   }
        // }
        return response.data
    },
    //接口错误状态处理，也就是说无响应时的处理
    error => {
        return Promise.reject(error.response.status) // 返回接口返回的错误信息
    })
export default axios;