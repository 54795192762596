<template>
  <div class="main inner clearFix">
    <div class="mainL fl menu">
      <p>{{ commonList.dictName }}</p>
      <template>
        <ul>
          <li
            v-for="(item, index) in commonList.children"
            :key="item.id"
            :class="{ cur: index == selectIndex }"
            @click="footSelect(index)"
            class="lili"
          >
            <span :class="index == selectIndex && show ? 'nums':'num'">{{
              setItem(index, commonList.children, "dictName")
            }}</span>
            <el-collapse-transition>
              <div v-show="index == selectIndex && show">
                <div
                  class="transition-box"
                  v-for="(items, index1) in item.children"
                  :key="index1"
                  @click.stop="selectSecons(index, index1, items.dictName)"
                >
                  {{ items.dictName }}
                </div>
              </div>
            </el-collapse-transition>
          </li>
        </ul>
      </template>
    </div>
    <div class="mainR fr">
      <div class="contents">
        <p>{{ title }}</p>
        <div class="contentsdiv" v-html="content"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    commonList: {
      type: Object,
      default: () => {
        return [];
      },
    },
    content: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
     selectFootIndex:{
       type:Number,
       default:0
     },
     footSeasonSelect:{
        type:Number,
       default:0
     }
        
  },
  data() {
    return {
      selectIndex: this.$store.state.footTabsSelect,
      selectSeconsIndex: 0,
      show: false,
    };
  },
  methods: {
    // 赋值
    setItem(index, arr, key) {
      let e = Object.assign({}, arr[Number(index)]);
      return e[key];
    },
    // 下面一级选中
    footSelect(index) {
      if (this.selectIndex == index) {
        this.selectIndex = index;
        if (this.show) {
          this.show = false;
        } else {
          this.show = true;
        }
      } else {
        this.selectIndex = index;
        this.show = true;
      }
    },
    // 二级选中
    selectSecons(index, index1, title) {
      this.selectSeconsIndex = index1;
      this.$store.commit("SetFootSeasonSelect", index1);
      this.$emit("setFootSeasonSelect", {
        selectIndex: index,
        selectSeconsIndex: index1,
        title: title,
      });
    },
   
  },
};
</script>
<style scoped>
.main {
  width: 1220px;
  padding: 20px 0 30px;
}
.linner {
  width: 1220px;
  margin: 0 auto;
}
.mainL {
  width: 285px;
}
.fl {
  float: left;
}
.mainL p {
  height: 60px;
  line-height: 60px;
  padding-left: 20px;
  font-size: 20px;
  color: #fff;
  background-color: #007bc7;
  margin: 0;
}
ul {
  display: flex;
  flex-direction: column;
}
.menu > ul > li {
  width: 100%;
  display: block;
  list-style: none;
  overflow: visible;

  align-items: center;
}
.menu > ul > li > .num {
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding-bottom: 0 !important;
  height: 50px;
  color: #333333;
  padding-left: 20px;
  line-height: 50px;
  background: #fafafa url(../../assets/img/commonparts/licur.jpg) no-repeat
    245px center;
  text-decoration: none !important;
  outline: none;
  font-weight: normal;
}
.cur .num {
  color: #007bc7 !important;
  background: #fafafa url(../../assets/img/commonparts/li.jpg) no-repeat 245px
    center !important;
}
.menu > ul > li > .nums {
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding-bottom: 0 !important;
  height: 50px;
  color: #333333;
  padding-left: 20px;
  line-height: 50px;
  background: #fafafa url(../../assets/img/commonparts/123.jpg) no-repeat
    245px center;
  text-decoration: none !important;
  outline: none;
  font-weight: normal;
}
.cur .nums {
  color: #007bc7 !important;
  background: #fafafa url(../../assets/img/commonparts/123.jpg) no-repeat 245px
    center !important;
}
.cur {
  border-left: 2px solid #fafafa;
}
.mainR {
  width: 920px;
}
.fr {
  float: right;
}
.contents {
  border: 1px solid #eaedec;
  padding: 30px 35px;
  line-height: 200%;
  min-height: 600px;
}
.contents p {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 20px;
}
.contentsdiv {
  line-height: 200%;
}
.lili {
  line-height: 1 !important;
}
.transition-box {
  background-color: #d4e6f2;
  font-size: 14px;
  color: #000;
  padding-left: 38px;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
