import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  
    
    ALLtabsList:JSON.parse(sessionStorage.getItem('ALLtabsList')), //全部菜单
   
    topTabsSelect:0, //顶部被选中下标
    footTabsSelect:0, //底部被选中下标
    footSeasonSelect:"", //底部二级被选中下标
    contentList:JSON.parse(sessionStorage.getItem('ContentList')),  //底部内容 数组
    TopImgList:JSON.parse(sessionStorage.getItem('TopImgList'))
  },
  mutations: {
    SetTopTabsSelect(state,index){
       state.topTabsSelect = index
    },
    SetFootTabsSelect(state,index){
      state.footTabsSelect = index
    },
    SetFootSeasonSelect(state,index){
     state.footSeasonSelect = index
    },
    SetALLtabsList(state,tabs){
      state.ALLtabsList = tabs
    },
    SetContentList(state,content){
      state.contentList = content
    },
    SetTopImgList(state,content){
      state.TopImgList = content
    }
  },
  actions: {
    SetTopTabsSelect({commit},index){
     
      commit('SetTopTabsSelect',index)
    },
    SetFootTabsSelect({commit},index){
    
      commit('SetFootTabsSelect',index)
    },
    SetFootSeasonSelect({commit},index){
      commit('SetFootSeasonSelect',index)
     },
    SetALLtabsList({commit},tabs){
      commit('SetALLtabsList',JSON.parse(tabs))
     
     },
     SetContentList({commit},content){
      commit('SetContentList',JSON.parse(content))
     },
     SetTopImgList({commit},content){
      commit('SetTopImgList',JSON.parse(content))
     }
  },
  modules: {
  }
})
