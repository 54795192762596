<template>
  <div class="box">
    <div class="CpTopbox">
      <CpTop
        @submitForm="submitForm"
        :commonList="commonList"
        :img="img"
        :title="title"
      ></CpTop>
      <CpFoot
        :commonList="commonList"
        @footSelectChange="footSelectChange"
        v-if="$route.query.topTabsSelect != 1"
        :title="title"
        :selectFootIndex="selectFootIndex"
        :content="content"
      ></CpFoot>
      <CpFootKJ
        :title="title"
        :commonList="commonList"
        @setFootSeasonSelect="setFootSeasonSelect"
        v-if="$route.query.topTabsSelect == 1"
        :selectFootIndex="selectFootIndex"
        :footSeasonSelect="footSeasonSelect"
        :content="content"
      ></CpFootKJ>
    </div>
  </div>
</template>
<script>
import CpFoot from "@/components/CommonParts/CpFoot";
import CpTop from "@/components/CommonParts/CpTop";
import CpFootKJ from "@/components/CommonParts/CpFootKJ";
export default {
  name: "CommonParts",
  components: {
    CpTop,
    CpFoot,
    CpFootKJ,
  },
  data() {
    return {
      img: "",
      title: "",
      selectTopIndex: this.$store.state.topTabsSelect,
      selectFootIndex: this.$store.state.footTabsSelect,
      footSeasonSelect: Number(this.$store.state.footSeasonSelect),
      commonList: {},
      content: "",
      contentList: this.$store.state.contentList,
    };
  },
  methods: {
    // 提交表单事件
    submitForm(e) {
      console.log(e);
      this.$http
        .post("/stock_admin/api/content/addContent", { ...e })
        .then((res) => {
           this.$message({
             type:res.code == 'S200'?'success':'error',
             message:res.msg
           })
         
        });
    },
    // 一级选中
    footSelectChange(e) {
      this.selectFootIndex = e;
      this.title = this.commonList.children[e].dictName;
      let arr = this.$store.state.contentList;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].title == this.title) {
          this.content = arr[i].content;
          break;
        }
      }
    },
    // 下边二级选中
    setFootSeasonSelect(data) {
      this.selectFootIndex = data.selectIndex;
      this.footSeasonSelect = Number(data.selectSeconsIndex);
      this.title = data.title;
      let arr = this.$store.state.contentList;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].title == this.title) {
          this.content = arr[i].content;
          break;
        }
      }
    },
    // 赋值
    setItem(index, arr, key) {
      let e = Object.assign({}, arr[index]);
      return e[key];
    },
  },
  created() {
    // let a = Number(this.$route.query.topTabsSelect);
    //  if(a != 1){
    //     this.img = this.commonList.img
    //     this.title = this.commonList.children[0].title
    //  }else{
    //     this.img =  this.commonList.img
    //
    //  }
    (this.commonList = this.$store.state.ALLtabsList[
      Number(this.$route.query.topTabsSelect)
    ]),
      (this.selectTopIndex = this.$route.query.topTabsSelect);
    
     
        let name = sessionStorage.getItem('topName')
       let ImgList = this.$store.state.TopImgList;
       for(let i = 0;i < ImgList.length;i++){
         if(ImgList[i].title == name){
           this.img = ImgList[i].imgPath
         }
       }
   
    let a = Number(this.$route.query.topTabsSelect);
    if (a != 1) {
      // this.img = this.commonList.dictImg;
      this.title = this.commonList.children[this.$store.state.footTabsSelect || 0].dictName;
    } else {
      // this.img = this.commonList.dictImg;
      this.title = this.commonList.children[this.$store.state.footTabsSelect || 0].children[this.$store.state.footSeasonSelect || 0].dictName;
    }
    this.$bus.$on("IndexToDetail", (e) => {
      this.title = e;
      for (let i = 0; i < this.contentList.length; i++) {
        if (this.contentList[i].title == this.title) {
          this.content = this.contentList[i].content;
          break;
        }
      }
    });
  },
  mounted() {
    const arr = JSON.parse(sessionStorage.getItem("ContentList"));
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].title == this.title) {
        this.content = arr[i].content;
        console.log(this.content);
        break;
      }
    }
  },

  updated() {},
};
</script>
<style scoped>
.box {
  width: 100%;
  height: 100%;
  background: #fff;
}
.CpTopbox {
  padding: 30px 0;
}
</style>
