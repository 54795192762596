import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './request/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import BaiduMap from 'vue-baidu-map'
// fade/zoom 等
import 'element-ui/lib/theme-chalk/base.css';
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
Vue.prototype.$http = axios
Vue.prototype.$bus = new Vue()
Vue.component(CollapseTransition.name, CollapseTransition)



Vue.use(ElementUI);
Vue.use(BaiduMap, {
  ak: 'zjblTex5buxbB66wXCBBGPVgfptrkdME'
})
Vue.config.productionTip = false


// 全局钩子
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
});


new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
