<template>
  <div class="register clearFix">
    <div class="fl pic">
      <img :src="img" alt="" />
    </div>
    <div class="fl bd">
      <h3>
        {{ title }}
      </h3>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="100px"
        class="registerForm"
      >
        <el-form-item label="行政区域:">
          <el-col :span="11">
            <el-select
              v-model="form.province"
              placeholder="请选择活动区域"
              @change="provinceChange"
            >
              <el-option
                :label="items.label"
                :value="items.label"
                v-for="items in city"
                :key="items.value"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="11">
            <el-select v-model="form.cintys" placeholder="请选择活动区域">
              <el-option
                :label="items.label"
                :value="items.label"
                v-for="items in cintysList"
                :key="items.value"
              ></el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="公司名称:" prop="company">
          <el-input v-model="form.company" class="heng"></el-input>
        </el-form-item>
        <el-form-item label="联系人:" prop="userName">
          <el-input v-model="form.userName" class="heng"></el-input>
        </el-form-item>
        <el-form-item label="联系电话:" prop="phone">
          <el-input v-model="form.phone" class="heng"></el-input>
        </el-form-item>
        <el-form-item label="业务内容:" prop=" content">
          <el-input v-model="form.content" class="heng"></el-input>
        </el-form-item>
        <el-form-item label="" class="btnfoot">
          <el-button type="primary" @click="submitForm('form')" class="elbuttom"
            >立即提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { shengJson } from "../../assets/js/city";
export default {
  props: {
    img: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    var peoplePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入信息"));
      } else {
        callback();
      }
    };
    var phonePass = (rule, value, callback) => {
      const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      console.log(reg.test(value));
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        if (!reg.test(value)) {
          callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      }
    };
    return {
      city: shengJson,
      cintysList: [],
      form: {
        province: "北京市",
        cintys: "东城区",
        company: "",
        userName: "",
        phone: "",
        content: this.title,
      },
      rules: {
        userName: [{ validator: peoplePass, trigger: "blur", required: true }],
        phone: [{ validator: phonePass, trigger: "blur", required: true }],
        content: [
          { required: true, trigger: "blur", message: "业务内容不能为空" },
        ],
      },
    };
  },

  methods: {
    submitForm(e) {
      this.$refs[e].validate((valid) => {
        if (valid) {
          console.log(this.form);
          this.$emit("submitForm", this.form);
          this.form = {
            province: "北京市",
            cintys: "东城区",
            company: "",
            userName: "",
            phone: "",
            content: this.title,
          };
        } else {
          return false;
        }
      });
    },
    provinceChange(e) {
      for (let i = 0; i < shengJson.length; i++) {
        if (shengJson[i].label == e) {
          this.cintysList = shengJson[i].children;
          this.$set(this.form, "cintys", shengJson[i].children[0].label);
        }
      }
    },
  },
  mounted() {},
  created() {
    this.city = shengJson;
  },
  watch: {
    title: function(newVal) {
      this.title = newVal;
      this.form.content = newVal;
    },
  },
};
</script>
<style>
.register {
  height: 335px;
  margin-top: 30px;
  width: 1220px;
  margin: 0 auto;
}
.register div.pic {
  width: 593px;
  height: 335px;
  padding-top: 0px;
}
.f1 {
  float: left;
}
.pic img {
  width: 100%;
  height: 100%;
}
.register div.bd {
  width: 579px;
  margin-left: 33px;
}
h3 {
  font-size: 24px;
  color: #333;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: left;
}
.el-input input {
  background: none;
  height: 30px !important;
  line-height: 30px !important;
  border-radius: 3px;
  border: 1px solid #d7d7d7;
  font-size: 14px;
  color: #555;
}
.el-select-dropdown__item {
  display: block;
  width: 100%;
}
.el-scrollbar__view {
  flex-direction: column;
}
.el-popper {
  margin-top: 0px !important;
}
.heng {
  width: 420px !important;
}
.heng input {
  width: 100%;
}
.el-form-item {
  margin: 5px 0 !important;
}
.elbuttom {
  width: 420px;

  padding: 12px 20px;
}
.elbuttom span {
  margin-top: -5px;
}
.registerForm {
  margin-top: 40px;
}
.el-form-item__error {
  top: 80% !important;
}
.btnfoot {
  margin-top: 10px !important;
}
</style>
