<template>
  <div class="weService">
    <div class="inner">
      <p class="myprojuecet">我们服务过的客户</p>
      <div class="lineL line"></div>
      <div class="lineR line"></div>
      <ul class="clearFix">
        <li v-for="item in imgList" :key="item">
          <img
            :src="item"
            alt=""
          />
        </li>
        
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
       imgList:[
          require("../../assets/img/foot0.png"),
          require("../../assets/img/foot1.png"),
          require("../../assets/img/foot2.png"),
          require("../../assets/img/foot3.png"),
          require("../../assets/img/foot4.png"),
          require("../../assets/img/foot5.png"),
          require("../../assets/img/foot6.png"),
          require("../../assets/img/foot7.png"),

       ]
    }
  }
}
</script>
<style  scoped>
.weService {
  width: 100%;
  height: 438px;
  background-color: #f4f6f7;
}
.weService .inner {
  position: relative;
  width: 1220px;
  margin: 0 auto;
  overflow: hidden;
}
.weService .myprojuecet {
  font-size: 28px;
  color: #333333;
  text-align: center;
  font-weight: 700;
  padding: 40px 0 73px;
}
.weService .line {
  width: 484px;
  height: 1px;
  background-color: #ebebeb;
  position: absolute;
  top: 70px;
}
.weService .lineL {
  left: 0;
}
.weService .lineR {
  right: 0;
}
.weService ul {
  width: 1240px;
  display: flex;
  flex-flow: row wrap;
}
.weService ul li {
  width: 281px;
  height: 89px;
  padding: 5px;
  background: url(../../assets/img/hzhb.png) no-repeat center;
  margin: 0 17px 17px 0;
}
.weService ul li img {
  display: block;
  width: 281px;
  height: 89px;
}
</style>