<template>
  <div class="tabsBox">
    <el-row :gutter="20">
      <el-col :span="6">
        <div class="leftbox">
          <img :src="setSrc(999)" alt="" @click="toDetail(0)" />
        </div>
      </el-col>
      <el-col :span="9">
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="imgbox" @click="toDetail(0)">
              <p class="bigfz">{{ setName(0) }}</p>
              <img :src="setSrc(0)" alt="" /></div
          ></el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 20px">
          <el-col :span="12"
            ><div class="imgbox" @click="toDetail(3)">
              <p class="smfz">{{ setName(3) }}</p>
              <img :src="setSrc(3)" alt="" /></div
          ></el-col>
          <el-col :span="12"
            ><div class="imgbox" @click="toDetail(4)">
              <p class="smfz">{{ setName(4) }}</p>
              <img :src="setSrc(4)" alt="" /></div
          ></el-col>
        </el-row>
      </el-col>
      <el-col :span="9">
        <el-row :gutter="20">
          <el-col :span="12"
            ><div class="imgbox" @click="toDetail(1)">
              <p class="smfz">{{ setName(1) }}</p>
              <img :src="setSrc(1)" alt="" /></div
          ></el-col>
          <el-col :span="12"
            ><div class="imgbox" @click="toDetail(2)">
              <p class="smfz">{{ setName(2) }}</p>
              <img :src="setSrc(2)" alt="" /></div
          ></el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 20px">
          <el-col :span="24">
            <div class="imgbox" @click="toDetail(5)">
              <p class="bigfz">{{ setName(5) }}</p>
              <img :src="setSrc(5)" alt="" /></div
          ></el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  props: {
    tabsIndex: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      selectList: [],
    };
  },
  created() {},
  mounted() {
    // let arr =this.$store.state.ALLtabsList || sessionStorage.getItem("ALLtabsList")
    // for(let i = 0;i < arr.length;i++){
    //   if(i == this.tabsIndex){
    //    this.selectList = arr[i].children
    //   }
    // }
  },
  methods: {
    setName(index) {
      let selectList = this.$store.state.ALLtabsList[this.tabsIndex].children;
      if (this.tabsIndex == 1) {
        selectList = selectList[0].children;
      }
      return selectList[index].dictName;
    },
    setSrc(index) {
      let img = "";
      if (index == 999) {
        img = this.$store.state.ALLtabsList[this.tabsIndex].dictImg;
      } else {
        let selectList = this.$store.state.ALLtabsList[this.tabsIndex].children;
        if (this.tabsIndex == 1) {
          selectList = selectList[0].children;
        }
        img = selectList[index].dictImg;
      }

      return img;
    },
    toDetail(e) {
      this.$bus.$emit("tabsQH", this.tabsIndex);
      sessionStorage.setItem('topName',this.$store.state.ALLtabsList[this.tabsIndex].dictName)
      sessionStorage.setItem("active", this.tabsIndex + 3);
      if (this.tabsIndex != 1) {
        this.$store.dispatch("SetTopTabsSelect", this.tabsIndex);
        this.$store.dispatch("SetFootTabsSelect", e);
        this.$router.push({
          name: "CommonParts",
          query: { topTabsSelect: this.tabsIndex },
        });
        this.$bus.$emit("IndexToDetail", this.setName(e));
      } else {
        this.$store.dispatch("SetTopTabsSelect", this.tabsIndex);
        this.$store.dispatch("SetFootTabsSelect", 0);
        this.$store.dispatch("SetFootSeasonSelect", e);
        this.$router.push({
          name: "CommonParts",
          query: { topTabsSelect: this.tabsIndex },
        });
        this.$bus.$emit("IndexToDetail", this.setName(e));
      }
    },
  },
  watch: {
    tabsIndex: {
      handler: function(newVal) {
        this.tabsIndex = newVal;
        let arr = JSON.parse(JSON.stringify(this.$store.state.ALLtabsList))[
          newVal
        ].children;
        if (newVal == 1) {
          arr = arr[0].children;
        }
        this.selectList = arr;
      },
    },
  },
  destroyed() {},
};
</script>
<style>
.el-carousel__container {
  width: 100%;
}
.tabsBox {
  position: relative;
  width: 1220px;
  height: 395px;
  padding: 0 20px !important;
  -webkit-box-shadow: 0 0 10px rgb(239 239 239 / 50%);
  -moz-box-shadow: 0 0 10px rgba(239, 239, 239, 0.5);
  box-shadow: 0 0 10px rgb(239 239 239 / 50%);
  margin: 0 auto;
  box-sizing: border-box;
  overflow: hidden;
}
.leftbox {
  width: 100%;
  height: 395px;
}
.leftbox img {
  width: 100%;
  height: 100%;
}
.imgbox {
  width: 100%;
  height: 190px;
  position: relative;
}
.imgbox img {
  width: 100%;
  height: 100%;
}
.bigfz {
  font-size: 24px;
  color: #343434;
  position: absolute;
  left: 23px;
  top: 40px;
  font-weight: normal;
}
.smfz {
  font-size: 16px;
  color: #343434;
  position: absolute;
  left: 23px;
  top: 40px;
  font-weight: normal;
}
</style>
